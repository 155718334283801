import React from 'react';
import { Checkbox,Tag } from 'antd';

const CheckboxGroup = Checkbox.Group;

const urlName = "inheritanceCheckedList"
class InheritanceCheckboxGroup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            inheritanceCheckedList: (props.inheritanceCheckedList === null ? props.inheritanceCheckboxOptions : props.inheritanceCheckedList),
            plainInheritanceOptions: (props.inheritanceCheckboxOptions === null ? [] : props.inheritanceCheckboxOptions),
            indeterminateInheritance: false,
            inheritanceCheckAll: true,
            urlParams: new URLSearchParams(window.location.hash.substring(1)),
        }
        props.saveInURL(urlName, JSON.stringify(this.state.inheritanceCheckedList))
        props.setFilter(this.getFilter(this.state.inheritanceCheckedList))
        // set checkAll and indeterminateInheritance correctly
        // checkAll can only be true when the total amount of options equals the amount of checked options
        this.state.inheritanceCheckAll = (this.state.inheritanceCheckedList.length === this.state.plainInheritanceOptions.length)
        this.state.indeterminateInheritance =                  // indeterminateInheritance when not all options are checked
            (this.state.inheritanceCheckedList.length !== this.state.plainInheritanceOptions.length) &&
            (this.state.inheritanceCheckedList.length !== 0)   // and the length of the list of checked options is not 0
    }

    // checkbox functions
    onChange = newInheritanceCheckedList => {
        this.setState((state) => {
            // refilter data, and set it in Results
            this.props.setFilter(this.getFilter(newInheritanceCheckedList))
            this.props.saveInURL(urlName, JSON.stringify(newInheritanceCheckedList))

            // check correct boxes
            return {
                inheritanceCheckedList: newInheritanceCheckedList,
                indeterminateInheritance: !!newInheritanceCheckedList.length && newInheritanceCheckedList.length < state.plainInheritanceOptions.length,
                inheritanceCheckAll: newInheritanceCheckedList.length === state.plainInheritanceOptions.length,
            }
        });
    };
    onCheckAllChange = e => {
        this.setState((state) => {
            // set the new filtered data in Results
            this.props.setFilter(() => e.target.checked)
            this.props.saveInURL(urlName, JSON.stringify(e.target.checked ? this.state.plainInheritanceOptions : []))
            
            // check correct boxes
            return {
                inheritanceCheckedList: e.target.checked ? state.plainInheritanceOptions : [],
                indeterminateInheritance: false,
                inheritanceCheckAll: e.target.checked,
            }
        });
    };

    getFilter = (inheritanceCheckedList) => {

        let heritList = []

        // map checkboxes to r.inheritance contents The idea is that With in the checkbox means that it will get all the ADs for example but for that it also needs to get the ARADs
        if (inheritanceCheckedList.length === 1 && inheritanceCheckedList.includes("With autosomal dominant inheritance")) {
            heritList = ["AD", "ARAD"]

        }
        if (inheritanceCheckedList.length === 1 && inheritanceCheckedList.includes("With autosomal recessive inheritance")) {
            heritList = ["AR", "ARAD"]

        }

        if (inheritanceCheckedList.length === 1 && inheritanceCheckedList.includes("Others")) {
            heritList = ["unknown"]

        }

        if (inheritanceCheckedList.length === 2 && inheritanceCheckedList.includes("With autosomal dominant inheritance") && inheritanceCheckedList.includes("With autosomal recessive inheritance")) {
            heritList = ["ARAD", "AR", "AD"]
        }

        if (inheritanceCheckedList.length === 2 && inheritanceCheckedList.includes("With autosomal dominant inheritance") && inheritanceCheckedList.includes("Others")) {
            heritList = ["ARAD", "AD", "unknown"]
        }

        if (inheritanceCheckedList.length === 2 && inheritanceCheckedList.includes("With autosomal recessive inheritance") && inheritanceCheckedList.includes("Others")) {
            heritList = ["ARAD", "AR", "unknown"]
        }

        if (inheritanceCheckedList.length === 3 && inheritanceCheckedList.includes("With autosomal recessive inheritance") && inheritanceCheckedList.includes("Others") && inheritanceCheckedList.includes("With autosomal dominant inheritance")) {
            heritList = ["AR","AD", "ARAD", "unknown"]
        }
        //
        return (r) => {
            // check if gene has the needed inheritance
            if(heritList.includes(r.inheritance)){
                    return true
                }

            return false
        }
    }


    render() {
        return ( 
            <div>
                
                <div className="site-checkbox-all-wrapper">
                    <Checkbox
                        indeterminate={this.state.indeterminateInheritance}
                        onChange={this.onCheckAllChange}
                        checked={this.state.inheritanceCheckAll} >
                        All genes
                    </Checkbox>
                </div>
                <br />
                <CheckboxGroup
                    options={this.state.plainInheritanceOptions}
                    value={this.state.inheritanceCheckedList}
                    onChange={this.onChange}
                />
            </div>
        )
    }




}

export default InheritanceCheckboxGroup

