import React from 'react';
import { Checkbox } from 'antd';

const CheckboxGroup = Checkbox.Group;

const urlName = "checkedList"
class GeneCheckboxGroup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            checkedList: (props.checkedList === null ? props.checkboxOptions : props.checkedList),
            plainOptions: (props.checkboxOptions === null ? [] : props.checkboxOptions),
            indeterminate: false,
            checkAll: true,
        }
        props.saveInURL(urlName, JSON.stringify(this.state.checkedList))
        props.setFilter(this.getFilter(this.state.checkedList))
        // set checkAll and indeterminate correctly
        // checkAll can only be true when the total amount of options equals the amount of checked options
        this.state.checkAll = (this.state.checkedList.length === this.state.plainOptions.length)
        this.state.indeterminate =                  // indeterminate when not all options are checked
            (this.state.checkedList.length !== this.state.plainOptions.length) &&
            (this.state.checkedList.length !== 0)   // and the length of the list of checked options is not 0
    }

    // checkbox functions
    onChange = newCheckedList => {
        this.setState((state) => {
            // refilter data, and set it in Results
            this.props.setFilter(this.getFilter(newCheckedList))
            this.props.saveInURL(urlName, JSON.stringify(newCheckedList))

            // check correct boxes
            return {
                checkedList: newCheckedList,
                indeterminate: !!newCheckedList.length && newCheckedList.length < state.plainOptions.length,
                checkAll: newCheckedList.length === state.plainOptions.length,
            }
        });
    };
    onCheckAllChange = e => {
        this.setState((state) => {
            // set the new filtered data in Results
            this.props.setFilter(() => e.target.checked)
            this.props.saveInURL(urlName, JSON.stringify(e.target.checked ? this.state.plainOptions : []))
            
            // check correct boxes
            return {
                checkedList: e.target.checked ? state.plainOptions : [],
                indeterminate: false,
                checkAll: e.target.checked,
            }
        });
    };


    // column filtering with checkboxes
    filterResults = (checkedList) => {
        // efficiency when loading page for the first time
        if(checkedList.length === this.state.plainOptions.length){
            return this.props.allData
        }
        let newResults = []
        let chrList = []
        let startList = []
        let endList = []
        
        // query example string: Chomosome Y: 1 to 1234567
        for( let query of checkedList ){
            chrList.push(query.split(":")[0].split(" ")[1])
            startList.push(query.split(":")[1].split(" ")[1])
            endList.push(query.split(":")[1].split(" ")[3])
        }
        
        // check every result, start from original query results
        for( let r of this.props.allData ){
            // check if gene is on one of the selected chromosomes
            if(chrList.includes(r.chromosome)){
                // all queries with same chromosome as gene
                let queryIndexes = []
                for( let i in chrList){
                    if(chrList[i] === r.chromosome){
                        queryIndexes.push(i)
                    }
                }
                // check each query with chromosome of gene
                let i = 0
                let addedResult = false;
                while( !addedResult && i < queryIndexes.length){
                    let queryIndex = queryIndexes[i]
                    // check if gene is on specified location of the query
                    if( (r.start <= startList[queryIndex] && r.end >= endList[queryIndex]) ||   // both boundaries of genes outside
                    (r.start >= startList[queryIndex] && r.start <= endList[queryIndex]) || // start inside
                    (r.end >= startList[queryIndex] && r.end <= endList[queryIndex]) ){     // end inside
                        newResults.push(r)
                        addedResult = true;
                    }
                    i++
                }
            }
        }
    
        return newResults
    }

    getFilter = (checkedList) => {
        let chrList = []
        let startList = []
        let endList = []
        
        // query example string: Chomosome Y: 1 to 1234567
        for( let query of checkedList ){
            chrList.push(query.split(":")[0].split(" ")[1])
            startList.push(query.split(":")[1].split(" ")[1])
            endList.push(query.split(":")[1].split(" ")[3])
        }
        return (r) => {
            // check if gene is on one of the selected chromosomes
            if(chrList.includes(r.chromosome)){
                // all queries with same chromosome as gene
                let queryIndexes = []
                for( let i in chrList){
                    if(chrList[i] === r.chromosome){
                        queryIndexes.push(i)
                    }
                }
                // check each query with chromosome of gene
                let i = 0
                while(i < queryIndexes.length){
                    let queryIndex = queryIndexes[i]
                    // check if gene is on specified location of the query
                    if( (r.start <= startList[queryIndex] && r.end >= endList[queryIndex]) ||   // both boundaries of genes outside
                    (r.start >= startList[queryIndex] && r.start <= endList[queryIndex]) || // start inside
                    (r.end >= startList[queryIndex] && r.end <= endList[queryIndex]) ){     // end inside
                        return true
                    }
                    i++
                }            
            }
            return false
        }
    }


    render() {
        return ( 
            <div>
                <div className="site-checkbox-all-wrapper">
                    <Checkbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll} >
                        All segments
                    </Checkbox>
                </div>
                <br />
                <CheckboxGroup
                    options={this.state.plainOptions}
                    value={this.state.checkedList}
                    onChange={this.onChange}
                />
            </div>
        )
    }
}
export default GeneCheckboxGroup