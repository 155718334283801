import React from 'react';
import { Table, Button, AutoComplete, Form, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

class GeneTable extends React.Component {

    constructor(props) {
        super(props)
        // Columnfilters is not saved in state, because when it updates,
        // table does not necessarily have to be rerendered
        this.columnFilters = props.columnFilters === null ? {} : props.columnFilters
        this.state = {
            dropDownVisible: false
        }
        props.setFilter(this.getFilter())
    }

    // column filtering with column search bar
    getColumnSearchProps(dataIndex, autoComplete = false) { return {
        filterDropdown: ({ confirm }) => {
            let filter = () => {
                this.setState({dropDownVisible: false})
                // save search term in url
                this.props.saveInURL("columnFilters", JSON.stringify(this.columnFilters))
                this.props.setFilter(this.getFilter())
                confirm();  // Makes sure the dropdown hides correctly
            }
            return (<div style={{ padding: 8, display: this.state.dropDownVisible ? 'block' : 'none' }}>
                    {/* Catch the submit event of the autocomplete in this Form */}
                    <Form onFinish={() => filter()}>
                        <AutoComplete
                            key={this.state.dropDownVisible} // re-render
                            autoFocus={true}
                            options={autoComplete ? this.getAutocompleteOptions(dataIndex) : []}
                            placeholder={`Search ${dataIndex}`}
                            defaultValue={this.columnFilters[dataIndex]}
                            onChange={value => {
                                this.updateColumnFilter(dataIndex, value)
                            }}
                            filterOption={(inputValue, option) =>
                                option.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            style={{ width: 300, display: 'block'}}
                        />
                    </Form>
                    <Button
                        type="primary"
                        onClick={() => filter()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            this.resetColumnFilter(dataIndex)
                            filter()
                        }} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>)
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) { this.setState({dropDownVisible: true}) }
        },
        filterIcon: <SearchOutlined style={{ color: this.columnFilters[dataIndex] === undefined ? undefined : '#1890ff'}} />,
        render: text =>
            (dataIndex in this.columnFilters) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.columnFilters[dataIndex]]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
                ) : (
                    text
                ),
    }}

    // Update the column filter for a dataIndex with value
    // doesn't change the url. That only happens on search
    updateColumnFilter = (dataIndex, value) => {
        if (this.columnFilters[dataIndex] !== value) {
            this.columnFilters[dataIndex] = value
        }
    }

    resetColumnFilter = (dataIndex) => {
        if (this.columnFilters[dataIndex] !== undefined) {
            delete this.columnFilters[dataIndex]
        }
    }

    // manual filter based on what's in columnFilters. Static because called in getDerivedStateFromProps
    getFilter = () => {
        let columnFilters = this.columnFilters

        // Filter
        return (r) => {
            for (let dataIndex in columnFilters) {
                if (!r[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(columnFilters[dataIndex].toLowerCase())) {
                    return false
                }
            }
            return true
        }
    }

    // get autocomplete options, filter uniques
    getAutocompleteOptions = (dataIndex) => {
        let options = new Set()
        // special rule for disease column
        if (dataIndex === "disorders") {
            this.props.data.forEach(record => {
                record.diseases.forEach(d => options.add(d.diseasename))
            })
        } else {
            this.props.data.forEach(record => options.add(record[dataIndex]))
        }
        // for safety
        options.delete(undefined)
        options.delete(null)
        options.delete("")
        return [...options].map(o => ({ value: o }))  // an Option is an object with a 'value'
    }

    //fwd this.props.data to state of gene_export_button

    handleChange = e => this.setState({ [this.props.data]: e.target.value });

    //rendering
    render() {

        const columns = [
            {
                title: 'Gene Id',
                dataIndex: 'geneid',
                sorter: (a, b) => a.geneid.localeCompare(b.geneid),
                width: '10%',
                ...this.getColumnSearchProps('geneid', true),
            },
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                width: '15%',
                sorter: (a, b) => a.symbol.localeCompare(b.symbol),
                ...this.getColumnSearchProps('symbol', true),
            },
            {
                title: 'Chromosome',
                dataIndex: 'chromosome',
                sorter: (a, b) => a.chromosome.localeCompare(b.chromosome),
                width: '7%',
                ...this.getColumnSearchProps('chromosome', true),
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                ...this.getColumnSearchProps('name', true),
            },
            {
                title: 'Disorders',
                dataIndex: 'disorders',
                sorter: (a, b) => b.disorders.localeCompare(a.disorders),
                width: '30%',
                ...this.getColumnSearchProps('disorders', true),
                // Custom render function for links
                render: (_text, record) =>
                    <>
                    {record.diseases.map((dis) => {
                        // highlight what is searched
                        let highlight = 'disorders' in this.columnFilters ? (
                            <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[this.columnFilters['disorders']]}
                                autoEscape
                                textToHighlight={dis.diseasename === null ? "unnamed disorder" : dis.diseasename.toString()}
                            />
                            ) : (
                                dis.diseasename === null ? "unnamed disorder" : dis.diseasename
                            )
                        if (dis.diseaseidforlink !== "") {
                            var split =  dis.diseaseidforlink.split(':')
                            var db = split[0]
                            var identifier = split[1]
                            var hpos = dis.hpos
                            var inheritanceList = []
                            // check what inheritance pattern
                            for (var i=0; i< hpos.length; i++) {
                                
                                var hpoDict = hpos[i]
                                for (var key in hpoDict) {
                                    if (hpoDict["hpo_id"] === "HP:0000007" && !inheritanceList.includes("[AR]")) {
                                        inheritanceList.push("[AR]")
                                    }
                                    if (hpoDict["hpo_id"] === "HP:0000006" && !inheritanceList.includes("[AD]")) {
                                        inheritanceList.push("[AD]")
                                    }
                                }

                            }
                            //

                            
                            if (db === "OMIM") {
                                return <>[OMIM] <a target="_blank" rel="noopener noreferrer"
                                    href={`http://www.omim.org/entry/${identifier}`}>{highlight}</a><a style={{ color: 'magenta' }}> {inheritanceList}</a> <br/></>

                            }
                            else if (db === "DECIPHER") {
                                return <>[DECIPHER] <a target="_blank" rel="noopener noreferrer"
                                    href={`https://decipher.sanger.ac.uk/syndrome/${identifier}`}>{highlight}</a><a style={{ color: 'magenta' }}> {inheritanceList}</a><br/></>
                            }
                            else if (db === "ORPHA") {
                                return <>[ORPHA] <a target="_blank" rel="noopener noreferrer"
                                    href={`https://www.orpha.net/consor/cgi-bin/OC_Exp.php?lng=EN&Expert=${identifier}`}>{highlight}</a><a style={{ color: 'magenta' }}> {inheritanceList}</a><br/></>
                            }
                            else if (dis.diseaseidforlink.startsWith("http")) {
                                return <><a target="_blank" rel="noopener noreferrer"
                                    href={`${dis.diseaseidforlink}`}>{highlight}</a><br/></>
                            }
                        }
                        return <>{highlight}<br/></>
                    }) // end map over diseases
                    }
                    </>
            },
        ];

        return this.props.dataLoading ?
                <Spin style={{ display: "block" }}/>
            :
                <Table
                    columns={columns}
                    dataSource={this.props.data}
                />
    }
}
export default GeneTable
