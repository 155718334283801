import React from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import XLSX from 'xlsx';
import { CSVLink } from "react-csv";

class ExportButton extends React.Component {



    // Can't create links in excel if you're not sponsor of sheetjs/xlsx
    exportToXLSX() {
        
        var header = [["Gene Id", "Symbol", "Chr", "Name", "Disorders"]]

        var rows = this.props.data.map(function (row) {
            var diseaseNameList = [];
            for (var i = 0; i < row.diseases.length; i++) {
                var detailDiseasenameArray = row.diseases[i]
                diseaseNameList.push(detailDiseasenameArray.diseaseidforlink+" name: "+detailDiseasenameArray.diseasename);
            }
            return [row.geneid, row.symbol, row.chromosome, row.name, diseaseNameList.join(' | ')]
        })

        var data = header.concat(rows)

        try {
            var wb = XLSX.utils.book_new()
            var sheet = XLSX.utils.aoa_to_sheet(data)
            // Set the column width
            sheet['!cols'] = [
                { wch: 10 },
                { wch: 15 },
                { wch: 4 },
                { wch: 50 },
                { wch: 150 }
            ]
            // Height is possible, for example multiple lines for multiple diseases.
            // Styling however is not possible, so you can't get multiple lines in Excel via 'text wrap'

            // Create the Excel workbook
            XLSX.utils.book_append_sheet(
                wb,
                sheet,
                "Gene List"
            )
            // 'Write'(download) the workbook on the client's computer
            XLSX.writeFile(wb, "gene_list.xlsx")
        } catch {
            alert("Something went wrong while exporting to Excel (xlsx)")
        }
    }

    render = () => {


        //for csv
        var header = [["Gene Id", "Symbol", "Chr", "Name", "Disorders"]]

        var rows = this.props.data.map(function (row) {
            var diseaseNameList = [];
            for (var i = 0; i < row.diseases.length; i++) {
                var detailDiseasenameArray = row.diseases[i]
                diseaseNameList.push(detailDiseasenameArray.diseaseidforlink+" name: "+detailDiseasenameArray.diseasename);
            }
            return [row.geneid, row.symbol, row.chromosome, row.name, diseaseNameList.join(' | ')]
        })

        var csvData = header.concat(rows)
        //

        let buttons =
            <Menu>
                <Menu.Item >
                    <CSVLink data={csvData} filename={"gene_list.csv"} target="_blank">CSV</CSVLink>
                </Menu.Item>
                <Menu.Item
                    onClick={() => this.exportToXLSX()} >
                    Excel (xlsx)
                </Menu.Item>
            </Menu>


        return (
            <div style={this.props.style}>
                <Dropdown overlay={buttons}>
                    <Button>
                        Export <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
        )
    }
}
export default ExportButton