import React from 'react';
import Select from 'react-select';
import { Button, Tag } from 'antd';
import { Input } from 'antd';
import { Divider,Card } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
    Link,
    withRouter
} from "react-router-dom";
import './form.css';
const { TextArea } = Input;


class Query extends React.Component {
    render() {
        return (
            <div>
                <ChromosomeNumber onChange={this.props.handleNumberChange} />
                <StartCoordinate onChange={this.props.handleStartChange} />
                <EndCoordinate onChange={this.props.handleEndChange} />
                <Button type="primary" onClick={this.props.onAddSegment}>Add segment</Button>
                <div>OR</div>
                <div>
                    <div>
                        Query:
                       
                    </div>
                    <TextArea rows={4} placeholder="for example: chr4:63502684-72360115 (each chromosome:start-stop on a separate line)" onChange={this.props.handleFormattedChange} />
                    <Button onClick={this.props.onLoadSegment}>Load segment</Button>
                </div>
            </div>
        );
    }
}

class StartCoordinate extends React.Component {
    render() {
        return (
            <div>
                <div>
                    Start coordinate :
                </div>
                <Input className="formText" type="text" placeholder="example: '62091121'" onChange={this.props.onChange} />
            </div>
        );
    }
}

class EndCoordinate extends React.Component {
    render() {
        return (
            <div>
                <div>
                    End coordinate:
                </div>
                <Input className="formText" type="text" placeholder="example: '78207557'" onChange={this.props.onChange} />
            </div>
        );
    }
}

class ChromosomeNumber extends React.Component {
    constructor(props) {
        super(props);
        this.options = [
            { value: 'X', label: 'chrX' },
            { value: 'Y', label: 'chrY' },
        ];
        for (let i = 1; i < 23; i += 1) {
            this.options.push({ value: i.toString(), label: 'chr' + i.toString() })
        }
    }

    render() {
        return (
            <div>
                Chromosome number:
                <Select
                    placeholder={'Select the chromosome number'}
                    value={this.props.value}
                    options={this.options}
                    onChange={this.props.onChange}
                />
            </div>
        )
    }
}

class AnalysisType extends React.Component {
    constructor(props) {
        super(props);
        this.options = [
            { value: 'ROH', label: 'Regions of homozygosity' },
            { value: 'MICRO', label: 'Microdeletions' },
        ];
    }

    render() {
        return (
            <div>
                Analysis Type:
                <Select
                    placeholder="Select an analysis type"
                    value={this.props.value}
                    options={this.options}
                    onChange={this.props.onChange}
                />
            </div>
        );
    }
}

class CoordinateUnit extends React.Component {
    constructor(props) {
        super(props);
        this.options = [
            
            { value: 'KB', label: 'Kb' },
            { value: 'MB', label: 'Mb' },
            { value: 'BASE', label: 'Base' },
        ];
    }

    render() {
        return (
            <div>
                Alternative Coordinate Unit:
                <Select
                    placeholder="leave blank for 'base'"
                    value={this.props.value}
                    options={this.options}
                    onChange={this.props.onChange}
                />
            </div>
        );
    }
}

class GenomeAssembly extends React.Component {
    constructor(props) {
        super(props);
        this.options = [
            { value: '16', label: 'NCBI34/hg16' },
            { value: '17', label: 'NCBI35/hg17' },
            { value: '18', label: 'NCBI36/hg18' },
            { value: '19', label: 'GRChr37/hg19' },
            { value: '38', label: 'GRChr38/hg38' },
        ];
    }

    render() {
        return (
            <div>
                Legacy Genome Assembly:
                <Select
                    placeholder="leave blank for GRChr38/hg38"
                    value={this.props.value}
                    onChange={this.props.onChange}
                    options={this.options}
                />
            </div>
        );
    }
}


class Popup extends React.Component {
    render() {
        if (this.props.showing) {
            return (
                <Card className="popup">
                    <CloseOutlined className="closeIcon" onClick={this.props.closePopup} />
                    <Query handleNumberChange={this.props.handleNumberChange}
                        handleStartChange={this.props.handleStartChange}
                        handleEndChange={this.props.handleEndChange}
                        handleFormattedChange={this.props.handleFormattedChange}
                        onAddSegment={this.props.onAddSegment}
                        onLoadSegment={this.props.onLoadSegment}
                    />

                </Card>
            );
        }
        return null;
    };
}


class Form extends React.Component {
    constructor(props) {
        super(props);
        // load info from url
        const urlParams = new URLSearchParams(window.location.hash.substring(1))
        this.state = {
            //analysisType: urlParams.get("analysisType"),
            coordinateUnit: urlParams.get("coordinateUnit"),
            genomeAssembly: (urlParams.get("genomeAssembly") === null ? 38 : urlParams.get("genomeAssembly")),
            showingPopup: false,
            segments: (urlParams.get("segments") === null ? [] : JSON.parse(urlParams.get("segments"))),
            chromosomeNumber: null,
            startCoordinate: null,
            endCoordinate: null,
            formattedQuery: null,
            finalQuery: null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAccessionNumberChange = this.handleAccessionNumberChange.bind(this);
        this.handleAddSegment = this.handleAddSegment.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
        this.handleFormattedChange = this.handleFormattedChange.bind(this);
        this.onAddSegment = this.onAddSegment.bind(this);
        this.onLoadSegment = this.onLoadSegment.bind(this);
        this.showSegmentDetails = this.showSegmentDetails.bind(this);
        this.makeFinalQuery = this.makeFinalQuery.bind(this)
    };

    handleSubmit(event) {
        alert(
            'A query was submitted: ' + this.state.segments + '\n' +
            'An analysis type was chosen: ' + this.state.analysisType + '\n' +
            'A coordinate unit was chosen: ' + this.state.coordinateUnit + '\n' +
            'A genome assembly was chosen: ' + this.state.genomeAssembly + '\n' +
            'An accession number was chosen: ' + this.state.accessionNumber + '\n'
        );
        event.preventDefault();
    };

    handleAnalysisTypeChange = selectedOption => {
        this.setState({ analysisType: selectedOption.value });
    };

    handleCoordinateUnitChange = selectedOption => {
        this.setState({ coordinateUnit: selectedOption.value });
    };

    handleGenomeAssemblyChange = selectedOption => {
        this.setState({ genomeAssembly: selectedOption.value });
    };

    handleAccessionNumberChange(event) {
        this.setState({ accessionNumber: event.target.value });
    };

    handleAddSegment() {
        this.setState({ showingPopup: true });
    };

    closePopup() {
        this.setState({ showingPopup: false });
    };

    handleNumberChange = selectedOption => {
        this.setState({ chromosomeNumber: selectedOption.value });
    };

    handleStartChange(event) {
        this.setState({ startCoordinate: event.target.value });
    };

    handleEndChange(event) {
        this.setState({ endCoordinate: event.target.value });
    };

    handleFormattedChange(event) {
        this.setState({ formattedQuery: event.target.value });
    };

    onAddSegment() {
        const segments = this.state.segments.slice();
        this.setState({
            segments: segments.concat(
                [{
                    chromosomeNumber: this.state.chromosomeNumber,
                    startCoordinate: this.state.startCoordinate,
                    endCoordinate: this.state.endCoordinate,
                }])
        });
        this.closePopup();
    };

    ontagClose(number) {
        
        this.setState((oldState) => {
            let segments = oldState.segments
            segments.pop(number)
            return { segments: segments}
        })

    }

    onLoadSegment() {
        // example query:   chr4:63502684-72360115
        //                  chr4:63502684-72360115
        //                  chr4:156193480-179785390
        //                  chr6:105054210-130034855
        //                  chr8:72445161-90890452
        //                  chr8:96951425-133821537
        //                  chr10:50528396-71933696
        for(let q of this.state.formattedQuery.split("\n")){
            let chr = parseInt(q.split(":")[0].slice(3));
            let start = parseInt(q.split(":")[1].split("-")[0]);
            let end = parseInt(q.split(":")[1].split("-")[1]);
            let duplicate = false;
            for(let s of this.state.segments){
                if(s !== undefined && s.chromosomeNumber === chr && s.startCoordinate === start && s.endCoordinate === end){
                    duplicate = true;
                }
            }
            if(duplicate) continue
           
            this.setState((state) => {
                const segments = state.segments.concat([{
                    chromosomeNumber: chr,
                    startCoordinate: start,
                    endCoordinate: end,
                }])
                return { segments: segments}
            })
        }
        this.closePopup();
    };

    // transform the list of segments to a query
    makeFinalQuery() {
        let chromosomeList = []
        let startList = []
        let endList = []
        for(let segment of this.state.segments){
            chromosomeList.push(segment.chromosomeNumber);
            startList.push(segment.startCoordinate);
            endList.push(segment.endCoordinate);
        }

        // make the query ( you should use setState here, but that the state isn't 
        //                                  updated in time for it to pass to the next page)
        this.state.finalQuery =
            "chr=" + chromosomeList.join() +
            "&start=" + startList.join() +
            "&end=" + endList.join() +
            "&assembly=" + this.state.genomeAssembly;

        // make the query
        // this.setState((state) => {return {finalQuery:"chr=" + chromosomeList.join() +
        //             "&start=" + startList.join() + 
        //             "&end=" + endList.join() + 
        //             "&assembly=" + state.genomeAssembly}});
    }

    showSegmentDetails(i) {
        return this.state.segments[i]
    }



    render() {
        const { tags } = this.state;
        // set info in url
        let options = "options#" +
            //"analysisType=" + this.state.analysisType +
            "&coordinateUnit=" + this.state.coordinateUnit +
            "&genomeAssembly=" + this.state.genomeAssembly +
            "&segments=" + JSON.stringify(this.state.segments)

        window.history.replaceState({}, "", options)

        const segments = this.state.segments.slice();
        const showingSegments = segments.map((segment, number) => {
            const li = <li key={number}>
                {<Tag closable onClose={() => this.ontagClose(number)}>{"chr" + segment.chromosomeNumber.toString() + ":" + segment.startCoordinate.toString() + "-" + segment.endCoordinate.toString()}</Tag>}
            </li>;
            return (li);
        });

        return (
            <div>
                <form>
                    <Tag color="green">🎉 databases updated Sept 14! See about tab for information.</Tag>
                    <Divider />
                    <div><Button onClick={this.handleAddSegment}>Add segment</Button></div>

                    <ul>{showingSegments}</ul>
                    {/* <AnalysisType onChange={this.handleAnalysisTypeChange} /> */}
                    <CoordinateUnit onChange={this.handleCoordinateUnitChange} />
                    <GenomeAssembly onChange={this.handleGenomeAssemblyChange} />
                    {/* <AccessionNumber onChange={this.handleAccessionNumberChange} /> */}


                    <Link to={{
                        pathname: "/results",
                        inputData: this.state
                    }}><Button type="primary" onClick={this.makeFinalQuery}>Submit Case</Button></Link>
                    
                </form>
                <Popup handleNumberChange={this.handleNumberChange}
                    handleStartChange={this.handleStartChange}
                    handleEndChange={this.handleEndChange}
                    handleFormattedChange={this.handleFormattedChange}
                    onAddSegment={this.onAddSegment}
                    onLoadSegment={this.onLoadSegment}
                    showing={this.state.showingPopup}
                    closePopup={this.closePopup}
                />
            </div>
        );
    };
}
export default withRouter(Form);
