import React from 'react';
import { Tree, Tag, Typography, Input, Spin, Button } from 'antd';
import Highlighter from 'react-highlight-words';

const { Search } = Input;

class HPOTree extends React.Component {


    constructor(props) {
        super(props)

        this.state = {
            tree: [],
            searchValue: props.searchValue === null ? '' : props.searchValue,
            error: props.dataError,
            autoExpandParent: true,
            expandedKeys: [],
            checkedKeys: props.checkedKeys === null ? [] : props.checkedKeys,
        }
        props.setFilter(this.getFilter(this.state.checkedKeys))
    };


    getTree = (results, shownDisorders) => {
        /**
         * Results format:
         * { "head": { "link": [],
         *             'vars": ["id", "lab", "p01_id", "p01_lab", ..., "p19_id", "p19_lab"]
         *           },
         *   "results": { "distinct": false,
         *                "ordered": true,
         *                "bindings": [
         *                      { "id": { "type": ...,
         *                                "datatype": ...,
         *                                "value": ...
         *                               },
         *                        "lab": { ... }, // also type, datatype and value
         *                        ...
         *                      },
         *                      { ... } // The second result
         *                      ]
         *              }
         * }
         *
         * We want to convert this to the format of data of Tree of antd
         * See https://ant.design/components/tree/
         */
        var root = {  // root
            title:
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchValue]}
                    autoEscape
                    textToHighlight={"All"}
                />,
            rawTitle: "All",
            key: "HP:0000001_1",
            hpoId: "HP:0000001",
            children: []
        }

        if (results === undefined) { return [[],[]] }
        let shownIds = new Set()
        shownDisorders.forEach(dis => dis.hpos.forEach(hpo => shownIds.add(hpo.hpo_id)))
        // If no Id's should be shown
        if (shownIds.size === 0) { return [[],[]] }
        let allIds = new Map()
        let dataList = []

        for (let result of results.results.bindings) {
            // Sort them: from child to furthest parent (e.g. id, lab, p01_id, p01_lab, p12_lab, p12_id, ..., p01_id, lab, id)
            let keys = Object.keys(result).sort()
            // Skip this node if it should not be shown
            if (!shownIds.has(result[keys[0]].value)) { continue }

            // path should start at root
            if (result[keys.pop()].value !== "All" || result[keys.pop()].value !== "HP:0000001") { continue }
            let currentNode = root
            while (keys.length) {
                let lab = result[keys.pop()].value
                let id = result[keys.pop()].value
                let nodeAssigned = false

                // Keys have to be unique, so add number
                let idNumber = allIds.get(id)
                if (idNumber === undefined) { idNumber = 0 }
                idNumber++
                let key = id + "_" + idNumber
                allIds.set(id, idNumber)

                // Check if node already in children
                for (let child of currentNode.children) {
                    if (child.hpoId === id) {
                        nodeAssigned = true
                        currentNode = child
                        break
                    }
                }
                if (nodeAssigned) {
                    continue
                } else {
                    // Add node to children
                    let newNode = {
                        title:
                            <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[this.state.searchValue]}
                                autoEscape
                                textToHighlight={lab}
                            />,
                        rawTitle: lab,
                        key: key,
                        hpoId: id,
                        children: [],
                        // we're adding this newNode as child to currentNode, so currentNode is parent
                        parentKey: currentNode.key
                    }

                    // add to dataList, needed for search
                    // Push whole node so this contains all info
                    dataList.push(newNode)

                    currentNode.children.push(newNode)
                    currentNode = newNode
                }
            }
        }
        return [[root], dataList]
    };

    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onChange = (dataList, e) => {
        // here we make a new array that had the parent key if it matches the search
        const expandedKeys = []
        dataList
            .forEach(item => {
                if (item.rawTitle.toUpperCase().includes(e.target.value.toUpperCase())) {
                    expandedKeys.push(item.parentKey)
                }
            })

        this.setState({
            expandedKeys,
            searchValue: e.target.value,
            autoExpandParent: true,
        });
    };

    onCheck = checkedKeys => {
        this.setState({
            checkedKeys,
        });
    };

    getFilter = (checkedKeys) => {
        if (!checkedKeys.length) {
            return () => true
        }

        let hposToDisplay = new Set()
        checkedKeys.forEach(key => hposToDisplay.add(key.split("_")[0]))

        return (r) => {
            for (let dis of r.diseases) {
                for (let hpoObj of dis.hpos) {
                    if (hposToDisplay.has(hpoObj.hpo_id)) {
                        return true
                    }
                }
            }
            return false
        }
    }

    handleFilter = () => {
        this.props.setFilter(this.getFilter(this.state.checkedKeys))
        this.props.saveInURL("checkedKeys", JSON.stringify(this.state.checkedKeys))
        this.props.saveInURL("searchValue", JSON.stringify(this.state.searchValue))
    };

    //TODO: werkt nog niet
    handleReset = () => {
        this.setState((oldState) => {
            let checkedKeys = []
            let searchValue = ""
            return {
                checkedKeys: checkedKeys,
                searchValue: searchValue
            }
        })
        this.props.setFilter(this.getFilter([]))
        this.props.saveInURL("checkedKeys", JSON.stringify([]))
        this.props.saveInURL("searchValue", JSON.stringify(""))
    };
    render() {
        let [tree, dataList] = this.getTree(this.props.data, this.props.disorders)
        // it returns this warning relatively often (server side problem ?)
        // height is for internal scroll bar for if the tree becomes too large
        return (
            <div style={{ paddingBottom: 15 }} >
                {this.props.dataError ? (
                    <Tag color="warning">Something went wrong while constructing the HPO tree.</Tag>
                ) : (
                        <>
                            <Typography level={4}>HPO tree (expand to select)</Typography>
                            {this.props.dataLoading ? (
                                <Spin style={{ margin: 30 }}/>
                            ) : (
                                <Search
                                    style={{ marginBottom: 8 }}
                                    placeholder="Search"
                                    defaultValue={this.state.searchValue}
                                    onChange={(e) => this.onChange(dataList, e)} />
                            )}
                            <Tree
                                checkable
                                height={600}
                                onCheck={this.onCheck}
                                checkedKeys={this.state.checkedKeys}
                                onExpand={this.onExpand}
                                expandedKeys={this.state.expandedKeys}
                                autoExpandParent={this.state.autoExpandParent}
                                treeData={tree}
                            />
                            <Button type="default" onClick={this.handleFilter}>Filter</Button>
                            <Button type="default" onClick={this.handleReset}>Reset</Button>
                        </>
                    )}
            </div>
        );
    }
}
export default HPOTree

