export var get_path_to_root = `
      PREFIX owl:  <http://www.w3.org/2002/07/owl#>
      PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
      PREFIX oboInOwl: <http://www.geneontology.org/formats/oboInOwl#>

      SELECT
         ?id ?lab
         ?p01_id ?p01_lab
         ?p02_id ?p02_lab
         ?p03_id ?p03_lab
         ?p04_id ?p04_lab
         ?p05_id ?p05_lab
         ?p06_id ?p06_lab
         ?p07_id ?p07_lab
         ?p08_id ?p08_lab
         ?p09_id ?p09_lab
         ?p10_id ?p10_lab
         ?p11_id ?p11_lab
         ?p12_id ?p12_lab
         ?p13_id ?p13_lab
         ?p14_id ?p14_lab
         ?p15_id ?p15_lab
         ?p16_id ?p16_lab
         ?p17_id ?p17_lab
         ?p18_id ?p18_lab
         ?p19_id ?p19_lab
      WHERE {
         ?c a owl:Class .
         ?c oboInOwl:id ?id .
         filter(regex(?id, "$$$$$"))
         ?c rdfs:label ?lab .
         OPTIONAL{
         ?c rdfs:subClassOf ?p01 .
         ?p01 oboInOwl:id ?p01_id .
         ?p01 rdfs:label ?p01_lab .
         OPTIONAL{
         ?p01 rdfs:subClassOf ?p02 .
         ?p02 oboInOwl:id ?p02_id .
         ?p02 rdfs:label ?p02_lab .
         OPTIONAL{
            ?p02 rdfs:subClassOf ?p03 .
            ?p03 oboInOwl:id ?p03_id .
            ?p03 rdfs:label ?p03_lab .
            OPTIONAL{
            ?p03 rdfs:subClassOf ?p04 .
            ?p04 oboInOwl:id ?p04_id .
            ?p04 rdfs:label ?p04_lab .
            OPTIONAL{
            ?p04 rdfs:subClassOf ?p05 .
            ?p05 oboInOwl:id ?p05_id .
            ?p05 rdfs:label ?p05_lab .
            OPTIONAL{
               ?p05 rdfs:subClassOf ?p06 .
               ?p06 oboInOwl:id ?p06_id .
               ?p06 rdfs:label ?p06_lab .
               OPTIONAL{
               ?p06 rdfs:subClassOf ?p07 .
               ?p07 oboInOwl:id ?p07_id .
               ?p07 rdfs:label ?p07_lab .
               OPTIONAL{
               ?p07 rdfs:subClassOf ?p08 .
               ?p08 oboInOwl:id ?p08_id .
               ?p08 rdfs:label ?p08_lab .
               OPTIONAL{
                  ?p08 rdfs:subClassOf ?p09 .
                  ?p09 oboInOwl:id ?p09_id .
                  ?p09 rdfs:label ?p09_lab .
                  OPTIONAL{
                  ?p09 rdfs:subClassOf ?p10 .
                  ?p10 oboInOwl:id ?p10_id .
                  ?p10 rdfs:label ?p10_lab .
                  OPTIONAL{
                  ?p10 rdfs:subClassOf ?p11 .
                  ?p11 oboInOwl:id ?p11_id .
                  ?p11 rdfs:label ?p11_lab .
                  OPTIONAL{
                     ?p11 rdfs:subClassOf ?p12 .
                     ?p12 oboInOwl:id ?p12_id .
                     ?p12 rdfs:label ?p12_lab .
                     OPTIONAL{
                     ?p12 rdfs:subClassOf ?p13 .
                     ?p13 oboInOwl:id ?p13_id .
                     ?p13 rdfs:label ?p13_lab .
                     OPTIONAL{
                     ?p13 rdfs:subClassOf ?p14 .
                     ?p14 oboInOwl:id ?p14_id .
                     ?p14 rdfs:label ?p14_lab .
                     OPTIONAL{
                        ?p14 rdfs:subClassOf ?p15 .
                        ?p15 oboInOwl:id ?p15_id .
                        ?p15 rdfs:label ?p15_lab .
                        OPTIONAL{
                        ?p15 rdfs:subClassOf ?p16 .
                        ?p16 oboInOwl:id ?p16_id .
                        ?p16 rdfs:label ?p16_lab .
                        OPTIONAL{
                        ?p16 rdfs:subClassOf ?p17 .
                        ?p17 oboInOwl:id ?p17_id .
                        ?p17 rdfs:label ?p17_lab .
                        OPTIONAL{
                           ?p17 rdfs:subClassOf ?p18 .
                           ?p18 oboInOwl:id ?p18_id .
                           ?p18 rdfs:label ?p18_lab .
                           OPTIONAL{
                           ?p19 rdfs:subClassOf ?p19 .
                           ?p19 oboInOwl:id ?p19_id .
                           ?p19 rdfs:label ?p19_lab .
                           }
                        }
                        }
                        }
                     }
                     }
                     }
                  }
                  }
                  }
               }
               }
               }
            }
            }
            }
         }
         }
         }
      }
      `
