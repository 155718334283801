import React from 'react';


class About extends React.Component {

   render() {
      return (
         
         <div>
            <h1>Whichgene ROH explorer</h1>

            <h2>About</h2>
            <p>Whichgene.com is a tool to investigate Runs of Homozygosity (ROH). It was developed by Helder Opdebeeck with the help of Klaas Goethals and Iwijn Voeten. It's possible to explore the ROHs on gene content.  The data which links the genomic coordinates to which genes are laying between those coordinates comes from the last feature table of the latest RefSeq genome assembly (currently GCF_000001405.39_GRCh38.p13_feature_table.txt.gz, downloaded on sept 1st) (<a href="ftp://ftp.ncbi.nlm.nih.gov/genomes/all/GCF/000/001/405/GCF_000001405.39_GRCh38.p13">FTP link</a>).<br></br> <br></br>The annotation of the genes in terms of diseases and HPO phenotype terms is derived from the HPO annotations. <a href="http://compbio.charite.de/jenkins/job/hpo.annotations/lastStableBuild/">"genes_to_phenotype.txt"</a> ("Build #1273 (Aug 11, 2020 3:08:48 PM)") provides the link between geneIDs to disorders and <a href="https://hpo.jax.org/app/download/annotation">"phenotype.hpoa"</a> provides the HPO terms that are associated to the disorders. It's also possible to filter on disorder. For this, we included a searchable phenotypic tree. This phenotypic tree comes from the Human Phenotype Ontology initiative. Currently, the HPO annotation version that provides this filtering is the HPO annotation release from March 2020(<a href="https://hpo.jax.org/app/news/March%202020">release link</a>). For more information email to <a href="mailto:helder.opdebeeck@ugent.be">helder.opdebeeck@ugent.be</a> or contact me via the chat widget at the bottom of the page. Subscribe <a href="http://eepurl.com/ha0Z5r">here</a> to the mailinglist to stay up to date !</p>


            <figure>
            <img src="/dbinfo.png"
                  alt="Schematic of how the different databases are integrated"
                  width="1429"
                  height="500"></img>

            <figcaption>Schematic of how the different databases are integrated</figcaption>

            </figure>
            <h2>Warranty, Disclaimer, and Copyright Notice</h2>
            <p>By using this site, you signify your assent to the Terms and Conditions set forth below. If you do not agree to all of these Terms and Conditions of use, do not use this site.
The contents of the Whichgene.com (WG) Site, such as text and other material contained on the WG Site ("Content") are for informational and educational purposes only. The use of the WG Site and the Content is at your own risk.
When using the WG Site, information will be transmitted over a medium that may be beyond the control and jurisdiction of Whichgene.com. Accordingly, The Whichgene.com assumes no liability for or relating to the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the WG Site.
The WG Site and the Content are provided on an "as is" basis. WHICHGENE.COM, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES' RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. Without limiting the foregoing, Whichgene.com makes no representations or warranties about the accuracy, reliability, completeness, currentness, or timeliness of the Content, software, text, or communications provided on or through the use of the WG Site.
In no event shall Whichgene.com be liable for any damages (including, without limitation, incidental and consequential damages, personal injury/wrongful death, lost profits, or damages resulting from lost data or business interruption) resulting from the use of or inability to use the WG Site or the Content, whether based on warranty, contract, tort, or any other legal theory, and whether or not Whichgene.com is advised of the possibility of such damages. Whichgene.com shall not be liable for any personal injury, including death, caused by your use or misuse of the WG Site or content.
This WG Site and content are provided to enhance knowledge and encourage progress in the scientific community and are to be used only for research and educational purposes. The WG Site and content are not intended to be used for medical advice, diagnosis, or treatment.
Whichgene.com authorizes you to view or download a single copy of the Content on the WG Site solely for your personal, noncommercial use. Any reproduction or use for commercial purpose is prohibited without the prior express written permission of Whichgene.com.
</p>


         </div>
      );
   }
}   
 
export default About;