import React from 'react';
import GeneTable from '../components/gene_table'
import HPOTree from '../components/hpo_tree'
import reqwest from 'reqwest'
import GeneCheckboxGroup from '../components/gene_checkbox_group';
import InheritanceCheckboxGroup from '../components/inheritance_checkbox_group';
import ExportButton from '../components/gene_export_button';
import { Alert } from 'antd';

import { Client } from 'virtuoso-sparql-client'
import { Divider,Tag } from 'antd';

class Results extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            apiLinkPrefix: "/api/?", // todo: change this in production its "apiLinkPrefix: "/api/?","// when running from local compu its "apiLinkPrefix: "https://whichgene.com/api/?"" or http://localhost:8000/api/?,
            apiQuery: props.inputData === undefined ? "" : props.inputData.finalQuery,
            checkboxOptions: [],
            inheritanceCheckboxOptions: [],
            // results
            data: {},
            dataLoading: true,
            phenotypeData: undefined,
            phenotypeDataLoading: true,
            phenotypeDataError: false,

            filteredData: [],

            // url
            urlParams: new URLSearchParams(window.location.hash.substring(1)),

            // filters
            hpoFilter: () => true,
            checkboxFilter: () => true,
            tableFilter: () => true,
            inheritanceCheckboxFilter: () => true,
        };


        // if no query was passed from Search, make one (from url)
        if(props.inputData === undefined){
            this.state.apiQuery =
                "chr="          + this.state.urlParams.get("chr")
                + "&start="     + this.state.urlParams.get("start")
                + "&end="       + this.state.urlParams.get("end")
                + "&assembly="  + this.state.urlParams.get("assembly")
        } else { // otherwise the url does not yet have a query
            // save query in url
            window.history.replaceState( {}, "",
                "results#" + this.state.apiQuery
            )
            this.state.urlParams = new URLSearchParams(window.location.hash.substring(1))
        };

        // set the checkbox options
        let chrList = this.state.apiQuery.split("&")[0].split("=")[1].split(",")
        let startList = this.state.apiQuery.split("&")[1].split("=")[1].split(",")
        let endList = this.state.apiQuery.split("&")[2].split("=")[1].split(",")
        for(let i = 0; i < Math.min(chrList.length, startList.length, endList.length); i+= 1){
            this.state.checkboxOptions.push("".concat(
                "Chomosome ", chrList[i] ,
                ": ", startList[i], " to ", endList[i])
            )
        };
        // build the options for the inheritance checkbox group
        this.state.inheritanceCheckboxOptions.push("With autosomal recessive inheritance")
        this.state.inheritanceCheckboxOptions.push("With autosomal dominant inheritance")
        this.state.inheritanceCheckboxOptions.push("Others")
    }

    
    // query DB functions
    componentDidMount() {
        this.getData(res => {
            // Custom column 'disorders'
            res.results = res.results.map((rec) => {
                rec.disorders = rec.diseases.map((dis) => dis.diseasename).join("\n")
                return rec
            })

            this.setState((state) => ({
                data: res,
                dataLoading: false,
                filteredData: res.results,
            }), () => {
                this.fetchPhenotypeData(); // fecth phenotype data after the gene data is fetched
                this.refineData({});
            })
        });
    }
    getData = callback => {
        reqwest({
            url: this.state.apiLinkPrefix + this.state.apiQuery,
            type: 'json',
            method: 'get',
            contentType: 'application/json',
            success: res => {
                callback(res);
            },
        });
    };


    // save info in url
    saveInURL = (name, value) => {
        this.state.urlParams.set(name, value)
        window.history.replaceState({}, "", "results#" + this.state.urlParams.toString())
    }
    getFromURL = (name) => {
        return this.state.urlParams.get(name)
    }

    fetchPhenotypeData = () => {
        let queries = require('../sparql_queries.js')
        let allHPOIds = new Set()
        this.state.filteredData.forEach(record =>
            record.diseases.forEach(disorder =>
                disorder.hpos.forEach(hpo => allHPOIds.add(hpo.hpo_id))
            )
        )
        let regex = "(" + Array.from(allHPOIds.keys()).join("|") + ")"
        let query = queries.get_path_to_root.replace("$$$$$", regex)

        const SparqlClient = new Client('https://whichgene.com/sparql');
        SparqlClient.setDefaultFormat("application/json")
        SparqlClient.query(query)
            .then((results) => {
                this.setState((state) => ({
                    phenotypeData: results,
                    phenotypeDataLoading: false
                }))
            })
            .catch(() => {
                this.setState({ phenotypeDataError: true })
            });
    };

    refineData = ({ hpoFilter, checkboxFilter, tableFilter, inheritanceCheckboxFilter} ) => {
        if(this.state.data.results !== undefined){
            this.setState((state) => {
                if(hpoFilter === undefined) hpoFilter = state.hpoFilter
                if(checkboxFilter === undefined) checkboxFilter = state.checkboxFilter
                if(tableFilter === undefined) tableFilter = state.tableFilter
                if(inheritanceCheckboxFilter === undefined) inheritanceCheckboxFilter = state.inheritanceCheckboxFilter
                return ({
                    hpoFilter: hpoFilter,
                    checkboxFilter: checkboxFilter,
                    tableFilter: tableFilter,
                    inheritanceCheckboxFilter: inheritanceCheckboxFilter,
                    filteredData: state.data.results.filter(obj => hpoFilter(obj) && checkboxFilter(obj) && tableFilter(obj) && inheritanceCheckboxFilter(obj)),
                })
            })
        }else {
            this.setState((state) => ({
                hpoFilter: hpoFilter === undefined ? state.hpoFilter : hpoFilter,
                checkboxFilter: checkboxFilter === undefined ? state.checkboxFilter : checkboxFilter,
                tableFilter: tableFilter === undefined ? state.tableFilter : tableFilter,
                inheritanceCheckboxFilter: inheritanceCheckboxFilter === undefined ? state.inheritanceCheckboxFilter : inheritanceCheckboxFilter,

            }))
        }
    }

    render() {
        return(
            <div>
                <Tag>Hi, Helder here, the maker of this tool. I'm finishing my thesis which is about this web-app. If you used this tool to analyse a patient ROH, could you summarize how it helped (or didn't help you) ? <br></br>Please get in touch via <a href="mailto:helder.opdebeeck@ugent.be">helder.opdebeeck@ugent.be</a> or contact me via the chat widget at the bottom of the page. Thanks a lot !</Tag>
                <Divider />
                <HPOTree
                    dataError={this.state.phenotypeDataError}
                    data={this.state.phenotypeData}
                    dataLoading={this.state.phenotypeDataLoading}
                    disorders={this.state.filteredData.flatMap(rec => rec.diseases)}
                    saveInURL={this.saveInURL}
                    checkedKeys={JSON.parse(this.getFromURL("checkedKeys"))}
                    searchValue={JSON.parse(this.getFromURL("searchValue"))}
                    setFilter={(hpoFilter) => this.refineData({hpoFilter: hpoFilter})}
                    />

                <Divider />

                {this.state.urlParams.get("assembly") != 38 ? (
                    <Tag>ROH checkboxes for now only available for hg38 based input</Tag>
                ) : (
                    <>
                <GeneCheckboxGroup
                checkboxOptions={this.state.checkboxOptions}
                checkedList={JSON.parse(this.getFromURL("checkedList"))}
                saveInURL={this.saveInURL}
                setFilter={(checkboxFilter) => this.refineData({checkboxFilter: checkboxFilter})}
                />
                </>    
                )}

                <Divider />

                <InheritanceCheckboxGroup
                inheritanceCheckboxOptions={this.state.inheritanceCheckboxOptions}
                inheritanceCheckedList={JSON.parse(this.getFromURL("inheritanceCheckedList"))}
                saveInURL={this.saveInURL}
                setFilter={(inheritanceCheckboxFilter) => this.refineData({inheritanceCheckboxFilter: inheritanceCheckboxFilter})}
                />


                <Divider />
                <ExportButton
                    style={{ textAlign: "right" }}
                    data={this.state.filteredData} />                     
                    {/* tableData, because when user filters only that should be exported */}
                <Divider />
                <GeneTable
                    data={this.state.filteredData}
                    dataLoading={this.state.dataLoading}
                    columnFilters={JSON.parse(this.getFromURL("columnFilters"))}
                    saveInURL={this.saveInURL}
                    setFilter={(tableFilter) => this.refineData({tableFilter: tableFilter})}
                />
            </div>
        );
    }
}

export default Results;
