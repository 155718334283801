import React from 'react';
import { Layout, Menu} from 'antd';
import { Link, withRouter } from "react-router-dom";
import logo from '../logo.svg';

const { Header, Content, Footer } = Layout;

const CustomLayout = (props) => {

    // when refreshing, instead of "url/" you get "url/options#&..." this makes that defaultSelectedKeys doesnt select the ROH analysis on refresh. similar thing happens when displaying the results. This below makes sure it does.  
    // this is because we are using defaultselectedkeys and making it dynamic and not selectedkeys
    if (props.location.pathname === "/options" | props.location.pathname === "/results") {
        props.location.pathname = "/"
    }

    return(
        
        <Layout className="layout">
            <Header>
            <div className="logo"/>

            <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={[props.location.pathname]}               
                style={{ lineHeight: '64px' }}               
            >

                <Menu.Item key="/" onClick={() => {window.history.replaceState({}, "", "")}} >
                    <img src={logo} width="31" height="31" />

                    <span>&nbsp;&nbsp;&nbsp;&nbsp;ROH analysis</span>                    
                    <Link to="/" />
                </Menu.Item>
                
                <Menu.Item key="/about" >
                    <span>About</span>                    
                    <Link to="/about"></Link>
                </Menu.Item>

                <Menu.Item key="/feedback" >
                    <span><b>Feedback</b></span>                    
                    <Link to="/feedback"></Link>
                </Menu.Item>
                
            </Menu>
            </Header>
            <Content style={{ padding: '0 50px' }}>
                <div style={{ background: '#fff', padding: 24, minHeight: 1000}}>
                    {props.children}
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Whichgene.com | Created by Klaas, Iwijn and Helder </Footer>
            <Footer style={{ textAlign: 'center' }}>Powered by <a href="http://www.ant.design/">Ant.design</a>  </Footer>
        </Layout>        

        );
}

export default withRouter(CustomLayout);

