import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import 'antd/dist/antd.css';
import Form from "./components/form";
import CustomLayout from "./containers/Layout";
import Results from './containers/Results';
import About from './containers/About';
import Feedback from './containers/Feedback';

export default function App() {
  return (
      <Router>
        <div>
          <Switch>



            <Route path="/results" component={
              (props) => <CustomLayout><Results inputData={props.location.inputData}/></CustomLayout>
            }/>

            <Route path="/about" component={
              (props) => <CustomLayout><About inputData={props.location.pathname}/></CustomLayout>
            }/>

            <Route path="/feedback" component={
              (props) => <CustomLayout><Feedback inputData={props.location.pathname}/></CustomLayout>
            }/>
            <Route path="/">
              <CustomLayout><Form /></CustomLayout>
            </Route>

          </Switch>
        </div>
      </Router>
  );
}
