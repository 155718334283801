import React from 'react';
import { Divider } from 'antd';


class Feedback extends React.Component {

   render() {
      return (
         
         <div>
            <h1>Reach out</h1>
            <p>Whichgene.com is a tool developed during a thesis project from Ghent University, Belgium and will be maintained into the future.<br></br> <br></br>Please reach out to communicate what you like and don't like about this app via <a href="mailto:helder.opdebeeck@ugent.be">helder.opdebeeck@ugent.be</a> or contact me via the chat widget at the bottom of the page. <br></br> <br></br> Right now, I'm in the process of collecting case studies for my thesis, which I have to submit by Aug 26th the latest. If you used this tool in the diagnosis of a patient case, could you write a small report of how you were able to use this tool for a case ? </p>
            <Divider />
            <h1>Stay up to date</h1>
            <p>Subscribe <a href="http://eepurl.com/ha0Z5r">here</a> to the mailinglist to stay up to date as I tweak the site, add features and update databases !</p>
         </div>
      );
   }
}   
 
export default Feedback;